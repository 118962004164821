import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: "https://a1d86351bbd0411ba7edb5ef79149bea@sentry.rt-itservices.co.uk/23",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

