import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contract from "./components/contracts/Contract";
import PreContract from "./components/contracts/PreContract";
import Completed from "./components/contracts/Completed";
import NewStartForm from "./components/nsp/NewStartForm";
import Confirmation from "./components/nsp/Confirmation";
import WrongDetails from "./components/contracts/WrongDetails";
import NotFound from "./components/NotFound";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="nsp" element={<NewStartForm />} />
                    <Route path="nsp/confirm" element={<Confirmation />} />
                    <Route path="contract" element={<Contract />} />
                    <Route
                        path="contract-confirmation"
                        element={<PreContract />}
                    />
                    <Route path="contract-completed" element={<Completed />} />
                    <Route path="wrong-details" element={<WrongDetails />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
