import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 500px)": {
        width: "95vw",
    },
};

export default function ReferenceExampleModal({
    setOpenReferenceLetter,
    site,
    siteAddress,
}) {
    return (
        <Box sx={style} className="reference-letter-modal">
            <section className="reference-letter">
                <b className="private-confidential">Private & Confidential</b>
                <h1>{site}</h1>
                <address>{siteAddress}</address>
                <p>{moment().format("DD-MM-YYYY")}</p>
                <b>
                    Please read the information on this form carefully and
                    completely.
                </b>
                <p>
                    I have applied for employment with {site} and have provided
                    information about my previous employment. I authorise {site}{" "}
                    to conduct a reference check with my present and/or previous
                    employer(s). I understand that reference information may
                    include, but not limited to, verbal and written inquiries or
                    information about my employment performance, professional
                    demeanour, rehire potential, dates of employment, salary and
                    employment history.{" "}
                </p>
                <p>
                    My signature below authorises my former or current employers
                    and references to release information regarding my
                    employment record with their organisation and to provide any
                    additional information that may be necessary for my
                    application for employment with {site}, whether this
                    information is positive or negative.
                </p>
                <p>
                    This form may be photocopied and these copies will be as
                    effective as the original form which I have signed.
                </p>
                <section className="details">
                    <div className="indv-detail">
                        <b>Name:</b>
                    </div>
                    <div className="indv-detail">
                        <b>Signature:</b>
                    </div>
                    <div className="indv-detail">
                        <b>Date:</b>
                        <p>{moment().format("DD-MM-YYYY")}</p>
                    </div>
                </section>
            </section>
            <Button
                variant="contained"
                color="error"
                onClick={() => setOpenReferenceLetter(false)}
            >
                Close
            </Button>
        </Box>
    );
}
