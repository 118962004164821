import { useState, useEffect } from "react";
import logo from "../images/Portal_Header_Jedi.webp";
import "./contract.scss";

const WrongDetails = () => {
    const [staffName, setStaffName] = useState("");

    useEffect(() => {
        let staff = sessionStorage.getItem("staff");
        setStaffName(JSON.parse(staff));
    }, []);

    return (
        <>
            {" "}
            <section className="home_header">
                <img src={logo} alt="Logos" />
                <div className="logo_right"></div>
            </section>
            <main className="wrong-details">
                <h2>Hi, {staffName}!</h2>
                <h3>
                    HR will review your details. Please check your emails for
                    further updates.
                </h3>
            </main>
        </>
    );
};

export default WrongDetails;
