import logo from "../images/Portal_Header_Jedi.webp";
import "./styles/newstart.scss";

export default function Confirmation() {
    return (
        <>
            {" "}
            <section className="home_header">
                <img src={logo} alt="Logos" />
                <div className="logo_right"></div>
            </section>
            <main className="new_start_form">
                <h1>
                    Your new start paper work has been sent to HR, please keep
                    an eye on your email for your contract coming through.
                </h1>
                <h2>If you need to contact HR you can do on:</h2>
                <a href="tel:01415301655">0141 530 1655</a>
                <a href="mailto:team@jedihrsolutions.co.uk">
                team@jedihrsolutions.co.uk
                </a>
            </main>
        </>
    );
}
