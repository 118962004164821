import "./styles/home.scss";
import { useState, useEffect } from "react";
import logo from "./images/Portal_Header_Jedi.webp";

const Home = () => {
    const [staffName, setStaffName] = useState("");

    // useEffect(() => {
    //     const staff = sessionStorage.getItem("staff");
    //     setStaffName(JSON.parse(staff));
    // }, []);
    return (
        <main className="home">
            <section className="home_header">
                <img src={logo} alt="Logos" />
                <div className="logo_right"></div>
            </section>

            <section className="main_content">
                {" "}
                <h1>Hello!</h1>
                <h2>Welcome to the onboarding portal.</h2>
            </section>
        </main>
    );
};

export default Home;
